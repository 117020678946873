import React from 'react';

const NotFoundPage = () => {
  return (
    <div style={{ textAlign: 'center', marginTop: '50px' }}>
      <h1>Oops! Page not found.</h1>
      <img
        src='NotFound.png'
        alt='404 - Not Found'
        style={{ width: '60%', marginTop: '20px' }}
      />
      <p style={{ marginTop: '20px' }}>
        We can't seem to find the page you're looking for.
      </p>
    </div>
  );
};

export default NotFoundPage;
