import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { fetchQuoteOfTheDayAPI } from './quoteOfTheDayAPI';

export const fetchQuoteOfTheDay = createAsyncThunk(
  'quoteOfTheDay/fetch',
  async (_, { rejectWithValue }) => {
    try {
      const data = await fetchQuoteOfTheDayAPI();
      return data;
    } catch (error) {
      return rejectWithValue(error.message);
    }
  }
);

const initialState = { quote: '', author: '', loading: false, error: null };

const quoteOfTheDaySlice = createSlice({
  name: 'quoteOfTheDay',
  initialState,
  reducers: {
    // todo
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchQuoteOfTheDay.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(fetchQuoteOfTheDay.fulfilled, (state, action) => {
        state.quote = action.payload.content;
        state.author = action.payload.author;
        state.loading = false;
      })
      .addCase(fetchQuoteOfTheDay.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
      });
  },
});

export default quoteOfTheDaySlice.reducer;
