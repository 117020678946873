import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { fetchQuoteOfTheDay } from '../quoteOfTheDaySlice';

const QuoteComponent = () => {
  const dispatch = useDispatch();
  const { quote, author, loading, error } = useSelector(
    (state) => state.quoteOfTheDay
  );

  useEffect(() => {
    dispatch(fetchQuoteOfTheDay());
  }, [dispatch]);
  return (
    <div className='quoteContainer'>
      {loading && <p>Loading...</p>}
      {error && <p>Error...no quote today...</p>}
      {quote && (
        <blockquote>
          "{quote}"<div>- {author}</div>
        </blockquote>
      )}
      <button onClick={() => dispatch(fetchQuoteOfTheDay())}>
        Get a Quote
      </button>
    </div>
  );
};
export default QuoteComponent;
