import React from 'react';
import QuoteComponent from '../features/quoteOfTheDay/components/QuoteComponent';

const HomePage = () => {
  const currentYear = new Date().getFullYear();
  return (
    <div className='app-container'>
      <main className='App'>
        <h1>Chris Elipas</h1>
        <br />
        <nav>
          <ul>
            <QuoteComponent />
          </ul>
        </nav>
      </main>
      <footer>
        <i>© {currentYear} All rights reserved.</i>
      </footer>
    </div>
  );
};

export default HomePage;
